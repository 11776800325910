import "./App.css";
import "nes.css/css/nes.min.css";
import Main from "./Main";
import Guestbook from "./Guestbook";
import Bookmark from "./Bookmark";
import React, { useState, useEffect } from "react";
import GuestbookForm from "./GuestbookForm";
import { getLatestVisitorPosts } from "./api";
let once = true;

function App() {
  let date = new Date();
  console.log(date.getMonth());
  if (once && [11, 0].includes(date.getMonth())) {
    new Snow();
    once = false;
  }
  const [GBformDisplay, setGBformDisplay] = useState("none");
  const [page, setPage] = useState("main");
  const [showDetail, setShowDetail] = useState(false);

  const [visitorContent, setVisitorContent] = useState([]);

  useEffect(() => {
    getLatestVisitorPosts((v) => {
      setVisitorContent(v);
    });
  }, []);

  if (window.screen.width > 800) {
    return (
      <div className="App noSelect">
        <Bookmark setPage={setPage} setShowDetail={setShowDetail} />
        <Main
          page={page}
          setPage={setPage}
          showDetail={showDetail}
          setShowDetail={setShowDetail}
        />
        <Guestbook
          setGBformDisplay={setGBformDisplay}
          visitorContent={visitorContent}
        />
        <GuestbookForm
          GBformDisplay={GBformDisplay}
          setGBformDisplay={setGBformDisplay}
          setVisitorContent={() =>
            getLatestVisitorPosts((v) => setVisitorContent(v))
          }
        />
      </div>
    );
  } else {
    return (
      <div className="AppMobile noSelect">
        <div className="container nes-container with-title is-centered is-dark component">
          <p className="title">SPARK NIKKI</p>
          <img src="./icon.gif" className="sprite"></img>
        </div>
        <Main
          page={page}
          setPage={setPage}
          showDetail={showDetail}
          setShowDetail={setShowDetail}
        />
        <Bookmark setPage={setPage} setShowDetail={setShowDetail} />
        <Guestbook
          setGBformDisplay={setGBformDisplay}
          visitorContent={visitorContent}
        />
        <GuestbookForm
          GBformDisplay={GBformDisplay}
          setGBformDisplay={setGBformDisplay}
          setVisitorContent={() =>
            getLatestVisitorPosts((v) => setVisitorContent(v))
          }
        />
      </div>
    );
  }
}

var Snow = function () {
  var c = this;
  let canvas = document.getElementById("snow");
  let ctx = canvas.getContext("2d");

  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  let W = canvas.width;
  let H = canvas.height;

  this.init = function () {
    c.particles = [];
    c.colors = [
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
    ];

    c.mp = 100; //max particles

    for (var i = 0; i < c.mp; i++) {
      var size = Math.random() * 4 + 5;
      c.particles.push({
        x: Math.random() * W, //x-coordinate
        y: Math.random() * H, //y-coordinate
        w: size,
        h: size,
        d: Math.random() * 20 + 30, //density
        vx: Math.random() * 7 - 3.5,
        fill: c.colors[Math.floor(Math.random() * c.colors.length)],
        s: Math.random() * 0.2 - 0.1,
      });
    }

    setInterval(function () {
      c.render();
    }, 1000 / 30);
  };

  this.resize = function () {
    // Nothing
  };

  //Lets draw the flakes
  this.render = function () {
    ctx.fillStyle = "transparent";
    ctx.fillRect(0, 0, W, H);

    ctx.beginPath();
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    for (var i = 0; i < c.mp; i++) {
      var p = c.particles[i];
      ctx.fillStyle = p.fill;
      ctx.fillRect(p.x, p.y, p.w, p.h);
    }
    ctx.fill();
    c.update_positions();
  };

  this.update_positions = function () {
    for (var i = 0; i < c.mp; i++) {
      var p = c.particles[i];
      p.a += p.s;
      p.y += p.d / 10;
      p.x += p.vx;

      if (p.x > W + 5 || p.x < -5 || p.y > H) {
        if (i % 3 > 0) {
          p.x = Math.random() * W;
          p.y = -10;
        }
      }
    }
  };

  this.init();
};

export default App;
