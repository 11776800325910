import "nes.css/css/nes.min.css";
import GuestbookContent from "./GuestbookContent";

function Guestbook(props) {
  return (
    <div className="component Guestbook">
      <div
        className="container nes-container is-dark with-title is-centered"
        style={{ flexGrow: "1", overflow: "hidden", overflowY: "auto" }}
      >
        <p className="title GuestBookTitle" style={{ position: "fixed" }}>
          GUESTBOOK
        </p>
        <GuestbookContent
          visitorContent={props.visitorContent}
        ></GuestbookContent>
      </div>
      <div
        className="container nes-container is-dark z is-centered button"
        style={{
          height: "10vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => props.setGBformDisplay("block")}
      >
        <p className="title" style={{ textAlign: "center" }}>
          POST!
        </p>
      </div>
    </div>
  );
}

export default Guestbook;
