import axios from "axios";
const url = "https://lspark.moe/";

export const getMainContent = (x, callback) => {
  axios.get(url + "get_last_x?table=Posts&x=" + x).then((response) => {
    callback(response.data);
  });
};

//next
export const getPreviousContent = (id, x, callback) => {
  axios
    .get(url + "get_previous_x?table=Posts&id=" + id + "&x=" + x)
    .then((response) => {
      callback(response.data);
    });
};

export const getLatestVisitorPosts = (callback) => {
  axios.get(url + "get_last_x?table=Visitor_Posts&x=20").then((response) => {
    callback(response.data);
  });
};

export const getAllVisitorPosts = (window) => {
  window.open(url + "get_all_visitor_posts", "_newtab");
};

export const insertVisitorPost = (post, username, callback) => {
  axios
    .post(url + "insert_visitor_post", {
      post: post,
      username: username,
    })
    .then((response) => {
      callback(response.data);
    });
};

export const getContentByMonth = (year, month, callback) => {
  axios
    .get(url + "get_all_by_month?table=Posts&year=" + year + "&month=" + month)
    .then((response) => {
      callback(response.data);
    });
};

export const getPortfolio = (callback) => {
  axios.get(url + "get_all?table=Portfolio").then((response) => {
    callback(response.data);
  });
};

//amazon doesn't allow direct linking and images have a temp link because of that, so we need to scrape that shit
// export const getAmazonImages = (amazonUrl, callback) => {
//   axios.get(amazonUrl).then((response) => {
//     console.log(response);
//   });
// };
