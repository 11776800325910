import { getAllVisitorPosts } from "./api";

function GuestbookContent(props) {
  //username post name);
  let posts = props.visitorContent;
  if (posts) {
    return (
      <>
        <div className="allowSelect" style={{ paddingBottom: "16px" }}>
          {posts.map((content) => (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "4px 0px 2px 0px",
                }}
              >
                <div
                  style={{
                    height: "2px",
                    backgroundColor: "white",
                    flexGrow: "1",
                  }}
                ></div>
                <div className="gContentDate">└ {content.date} ┘</div>
                <div
                  style={{
                    height: "2px",
                    backgroundColor: "white",
                    flexGrow: "1",
                  }}
                ></div>
              </div>
              <div className="gContent" style={{ padding: "2px 0px" }}>
                {content.username}:
              </div>
              <div className="gContent" style={{ padding: "4px 0px 8px 0px" }}>
                {" "}
                {content.post}
              </div>
            </>
          ))}
        </div>
        <div onClick={() => getAllVisitorPosts(window)}>* All *</div>
      </>
    );
  }
}
export default GuestbookContent;
