import "nes.css/css/nes.min.css";
import Content from "./Content";
import React, { useState, useEffect } from "react";
import { getMainContent, getPreviousContent } from "./api";

function Main(props) {
  const [currentContent, setCurrentContent] = useState({});
  //this is dogshit I realized just now I don't need states for this arrow mess, but it's a pain to clean it up so while I don't find a bug whatever
  const [lVisibility, setLVisibility] = useState("hidden");
  const [rVisibility, setRVisibility] = useState("visible");

  useEffect(() => {
    getMainContent(1, (v) => {
      if (v[v.length - 1].id === 1) {
        setRVisibility("hidden");
      }
      setCurrentContent({ c: v, i: 0 });
    });
  }, []);

  return (
    <div className="component Main">
      <div
        className="container nes-container is-dark"
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: "1",
          overflowX: "hidden",
          overflowY: "auto",
          justifyContent: "space-between",
        }}
      >
        <Content
          page={props.page}
          setPage={props.setPage}
          content={currentContent}
          showDetail={props.showDetail}
          setShowDetail={props.setShowDetail}
          setCurrentContent={setCurrentContent}
          setLVisibility={setLVisibility}
          setRVisibility={setRVisibility}
        ></Content>
        <Arrows
          page={props.page}
          currentContent={currentContent}
          setCurrentContent={setCurrentContent}
          setLVisibility={setLVisibility}
          setRVisibility={setRVisibility}
          lVisibility={lVisibility}
          rVisibility={rVisibility}
        ></Arrows>
      </div>
    </div>
  );
}

function Arrows(props) {
  let page = props.page;
  let currentContent = props.currentContent;
  let setCurrentContent = props.setCurrentContent;
  let setLVisibility = props.setLVisibility;
  let setRVisibility = props.setRVisibility;
  let lVisibility = props.lVisibility;
  let rVisibility = props.rVisibility;
  if (page === "main") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          className="arrow"
          style={{
            fontSize: "2vw",
            fontFamily: "PixArrows",
            visibility: lVisibility,
          }}
          onClick={() => {
            if (currentContent.i === currentContent.c.length - 1) {
              setRVisibility("visible");
            }
            if (currentContent.i === 1) {
              setLVisibility("hidden");
            }
            if (currentContent.i !== 0) {
              setCurrentContent({
                ...currentContent,
                i: currentContent.i - 1,
              });
            }
          }}
        >
          4
        </div>
        <div
          className="arrow"
          style={{
            fontSize: "2vw",
            fontFamily: "PixArrows",
            visibility: rVisibility,
          }}
          onClick={() => {
            if (currentContent.i === 0) {
              setLVisibility("visible");
            }
            if (currentContent.i !== currentContent.c.length - 1) {
              setCurrentContent({
                ...currentContent,
                i: currentContent.i + 1,
              });
              console.log(currentContent.c[currentContent.i + 1]);
              console.log(currentContent.i);
              if (currentContent.c[currentContent.i + 1].id === 1) {
                setRVisibility("hidden");
              }
            } else {
              getPreviousContent(
                currentContent.c[currentContent.c.length - 1].id,
                currentContent.i + 1,
                (v) => {
                  let cC = currentContent.c.concat(v);
                  if (cC[cC.length - 1].id === 1) {
                    setRVisibility("hidden");
                  }
                  if (cC.length > currentContent.i + 1) {
                    setCurrentContent({
                      c: cC,
                      i: currentContent.i + 1,
                    });
                  }
                }
              );
            }
          }}
        >
          5
        </div>
      </div>
    );
  }
}

export default Main;
